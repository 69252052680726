.heading-container {
  position: relative;
  background-color: #181820;
  overflow: hidden;
  height: 100vh;

  .circle {
    position: absolute;
    left: -25%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &--1 {
      width: 590px;
      height: 520px;
      z-index: 8;
      background: radial-gradient(ellipse, #fefefe 90%, transparent 85%);
      filter: blur(100px);
    }
    &--2 {
      width: 1210px;
      height: 1820px;
      z-index: 7;
      background: radial-gradient(ellipse, #446df3 -10%, transparent 70%);
      filter: blur(50px);
    }
    &--3 {
      width: 1250px;
      height: 2170px;
      z-index: 6;
      background: radial-gradient(ellipse, #182347 40%, transparent 70%);
    }
    &--4 {
      width: calc(100vw + 25%);
      height: 1270px;
      z-index: 5;
      background: radial-gradient(#1b1d2c 40%, transparent 80%);
    }
  }

  .noise {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
  }

  .glow-circle {
    &-container {
      margin: 20rem;
      position: absolute;
      left: 24%;
      top: -690px;
    }
    width: 10px;
    height: 10px;
    background: #fefefe;
    border-radius: 50%;
    position: relative;
    top: 117px;
    left: 167px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      height: 450px;
      border-radius: 50%;
      box-shadow:
        0 0 100px 150px #fefefe,
        0 0 250px 300px #446df3,
        0 0 300px 350px #0632c2,
        0 0 400px 500px #181820;
    }
  }

  .heading,
  .back-button-container {
    display: flex;
    flex-direction: column;
    z-index: 90;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 240px * 2);
  }

  .heading .text-block--600,
  .heading .text-block--850 {
    display: none;
  }

  .actions {
    position: absolute;
    top: 75%;
    z-index: 100;
    width: calc(100% - 240px * 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 50%;
    transform: translate(-50%, -50%);

    .text {
      font-family: "Nunito", sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 32.74px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      width: 460px;
      color: #fff;
      margin: 0;
      padding: 0;
    }

    .button {
      justify-self: start;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 4px;
      background: linear-gradient(203deg, #4383ff -4.85%, #a0d1f4 152.7%);
      padding: 18px 36px;
      color: #fff;
      text-align: center;
      font-family: "Nunito_Sans", sans-serif;
      font-size: 16px;
      line-height: 25px;
      text-transform: uppercase;
      border: none;
      align-self: start;
      text-decoration: none;
    }
  }

  .back-button-container {
    top: 25%;
    display: flex;
    align-items: start;
  }

  .back-button {
    display: flex;
    gap: 10px;
    cursor: pointer;

    span {
      font-family: "Nunito", sans-serif;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.018em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    img {
      width: 24px;
    }
  }
}

@media screen and (min-width: 300px) {
  .heading-container {
    .heading {
      z-index: 90;
    }

    .back-button-container {
      top: 18%;
      width: calc(100% - 14px * 2);

      span {
        font-size: 20px;
      }
    }

    .heading {
      width: calc(100% - 51px * 2);
      top: 55%;

      .text-block--300 {
        display: flex;
        flex-direction: column;

        .text {
          font-size: 48px;
          line-height: 59.52px;

          &--white {
            margin-left: 44px;
          }
        }
      }
    }

    .circle {
      left: -50%;
      z-index: 1;
      &--1 {
        width: 200px;
        height: 600px;
      }
      &--2 {
        width: 510px;
        height: 900px;
      }
      &--3 {
        width: 650px;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 300px) {
  .heading-container {
    height: 90vh;
    .heading {
      width: calc(100% - 20px * 2);
      display: flex;
      top: 47%;

      .text {
        font-family: "Unbounded", sans-serif;
        font-size: 54px;
        font-weight: 300;
        line-height: 66.96px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        &--white {
          color: #fff;
        }

        &--blue {
          background-image: linear-gradient(to right, #a0d1f4, #4383ff);
          color: transparent;
          background-clip: text;
        }
      }

      .text-block--300 {
        display: flex;
        flex-direction: column;

        .text {
          font-size: 42px;
          line-height: 59.52px;

          &--white {
            margin-left: 44px;
          }
        }
      }

      &.ua .text {
        font-size: 38px;
      }
    }

    .actions {
      width: calc(100% - 20px * 2);
      flex-direction: column;
      top: 85%;

      .text {
        font-size: 16px;
        font-weight: 300;
        line-height: 21.82px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        width: auto;
        color: #fff;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 420px) {
  .heading-container .heading .text-block--300 .text {
    font-size: 48px;
  }
}

@media screen and (min-width: 600px) {
  .heading-container {
    .heading.ua .text {
      font-size: 54px;
    }

    .heading .text-block--300 {
      display: none;
    }

    .heading .text-block--600 {
      display: flex;
      flex-direction: column;
      padding-right: 20px;

      .text {
        &:nth-child(2) {
          margin-left: 28px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          align-self: end;
        }
      }
    }

    .circle {
      left: -50%;
      z-index: 1;
      &--1 {
        width: 350px;
        height: 600px;
      }
      &--2 {
        width: 710px;
        height: 1400px;
      }
      &--3 {
        width: 900px;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .heading-container {
    height: 90vh;

    .back-button-container {
      top: 18%;
      width: calc(100% - 40px * 2);

      span {
        font-size: 20px;
      }
    }

    .heading {
      width: calc(100% - 40px * 2);
      display: flex;
      top: 45%;
    }

    .actions {
      width: calc(100% - 40px * 2);
      gap: 24px;
      top: 80%;
      margin-top: 20px;

      .text {
        max-width: 464px;
        align-self: start;
        font-size: 24px;
        font-weight: 300;
        line-height: 32.74px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .heading-container {
    .heading {
      z-index: 90;

      .text {
        font-size: 62px;
      }
    }

    .back-button-container {
      top: 18%;
      width: calc(100% - 40px * 2);

      span {
        font-size: 20px;
      }
    }

    .heading {
      width: calc(100% - 51px * 2);
      top: 45%;
    }
  }
}

@media screen and (min-width: 850px) {
  .heading-container {
    .heading .text-block--600 {
      display: none;
    }

    .heading {
      .text-block--850,
      .blue-text-block {
        display: flex;
        flex-direction: column;

        .white-text-block {
          display: flex;
          flex-direction: column;
          justify-content: end;
        }

        .text {
          &--white {
            display: inline;
            align-self: end;
          }
        }
      }

      .text-block--850.ua {
        .white-text-block {
          display: inline;
          .text:last-child {
            margin-left: 1rem;
          }
        }
      }
    }

    .actions {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      top: 80%;

      .text {
        margin: 0;
      }

      .button {
        width: 300px;
      }
    }
  }
}

@media screen and (min-width: 1050px) {
  .heading-container {
    height: 100vh;
    .heading {
      top: 50%;
      .text-block--850 .white-text-block {
        flex-direction: row;
        gap: 1rem;
      }
    }

    .actions {
      width: calc(100% - (61px * 2));
      margin: 0 61px;
      left: 0;
      transform: unset;
      top: 72%;
    }

    .circle {
      left: -45%;
      z-index: 1;
      &--1 {
        width: 48%;
        height: 50%;
      }
      &--2 {
        width: 100%;
        height: 120%;
      }
      &--3 {
        width: 82%;
        height: 300%;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 1202px) {
  .heading-container {
    height: 100vh;
    .heading {
      .text-block--850.ua {
        .white-text-block {
          display: flex;
          .text:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .heading-container {
    .circle {
      left: -45%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .heading-container {
    .circle {
      left: -40%;
    }
  }
}

@media screen and (min-width: 1250px) {
  .heading-container {
    .heading .text-block--850 .blue-text-block {
      flex-direction: row;
      gap: 1rem;

      span {
        background-image: linear-gradient(to top, #a0d1f4 10%, #4383ff);
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .heading-container {
    .heading .text-block--850 .blue-text-block {
      flex-direction: column;
      span {
        &:last-child {
          align-self: center;
        }
      }
    }

    .heading,
    .back-button-container {
      width: calc(100% - 126px * 2);
    }

    .actions {
      width: calc(100% - 126px * 2);
      margin-left: 50%;
      transform: translateX(-50%);
    }

    .circle {
      left: -45%;
      z-index: 1;
      &--1 {
        width: 48%;
      }
      &--2 {
        width: 80%;
      }
      &--3 {
        width: 82%;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .heading-container .heading .text-block--850 .blue-text-block {
    flex-direction: column;
  }
}

@media screen and (min-width: 1550px) {
  .heading-container {
    .heading .text-block--850 .blue-text-block {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 1910px) {
  .heading-container {
    .heading {
      width: calc(100% - 240px * 2);
      flex-direction: column;
      align-items: center;
      top: 45%;
    }

    .actions {
      width: calc(100% - 240px * 2);
    }

    .back-button-container {
      width: calc(100% - 240px * 2);
    }

    .circle {
      left: -25%;
      &--1 {
        width: 590px;
      }
      &--2 {
        width: 1210px;
      }
      &--3 {
        width: 1250px;
      }
      &--4 {
        width: calc(100vw + 25%);
      }
    }
  }
}
