.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  &__inputs-container,
  &__input-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__input {
    position: relative;
    background-color: transparent;
    border: none;
    padding-bottom: 15px;
    border-bottom: 1px solid #eeedf2;
    font-family: "Unbounded", sans-serif;
    font-size: 18px;
    color: #fff;
    transition: all 0.3s;

    &::placeholder {
      font-family: "Unbounded", sans-serif;
      font-weight: 300;
      color: #fff;
      letter-spacing: 1px;
      overflow-wrap: break-word;
      flex-wrap: wrap;
    }

    &:focus {
      outline: none;
      border-color: #415dbd;
    }

    &:last-child::placeholder {
      white-space: normal;
    }
  }

  &__labels-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    p {
      color: #eeedf2;
      font-size: 16px;
      font-family: "Unbounded", sans-serif;
      letter-spacing: 1px;
      margin-top: 40px;
      margin-bottom: 5px;
      font-weight: 300;
    }
    &__rows-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__row {
      display: flex;
      gap: 16px;
    }

    .label {
      border-radius: 8px;
      padding: 8px 14px;
      background-color: #29282d;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      font-family: "Nunito_sans", sans-serif;
      font-weight: 300;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 14px 0 #3988ff;
      }
    }

    .selected {
      background: linear-gradient(to right top, #a0d1f4, #1849ec);
      color: #000;
      font-weight: 400;
    }
  }

  &__button {
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 4px;
    background: linear-gradient(203deg, #4383ff -4.85%, #a0d1f4 152.7%);
    padding: 18px 36px;
    color: #fff;
    text-align: center;
    font-family: "Nunito_Sans", sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    border: none;
    align-self: start;
    z-index: 101;

    &:hover {
      box-shadow: 0 0 14px 0 #3988ff;
    }

    &--sent {
      background-color: #0065fc;

      & img {
        animation: bounce 2s ease-in-out infinite;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .form {
    width: 48%;
    &__labels-container {
      display: flex;
      width: 100%;
      &__row {
        flex-wrap: wrap;
      }
    }
  }

  .form-full {
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .form {
    width: 60%;
  }

  .form-full {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .form {
    width: 100%;

    &__labels-container__rows-container {
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 768px) {
  .form {
    &__labels-container__rows-container {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 500px) {
  .form {
    width: 100%;
    &__input {
      &:last-child {
        padding-bottom: 35px;
      }
    }

    &__labels-container {
      display: flex;
      width: 100%;
      &__row {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .form {
    &__labels-container {
      display: flex;
      width: 90%;
      &__row {
        flex-wrap: wrap;
      }
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: rotate(90deg) translateX(0);
  }
  50% {
    transform: rotate(90deg) translateX(-5px); /* Adjust the bounce height as needed */
  }
}
