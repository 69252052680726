.portfolio-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 160px;
  padding: 112px 0;

  * {
    color: #1a1a22;
  }

  .heading-container {
    margin-bottom: 208px;
  }

  .portfolio-header {
    color: #1a1a22;
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0 0 15px 0;
    text-align: left;
  }

  .portfolio-link {
    text-decoration: none;
    color: #1a1a22;
    font-family: "Nunito_Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.36px;
    display: flex;
    align-items: center;
    padding: 12px 0;
    gap: 8px;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    gap: 160px;
  }

  .project-item {
    display: flex;
    flex-direction: column;
    //margin-bottom: 160px;

    .project-image-container {
      position: relative;
      overflow: hidden;
      border-radius: 16px;
      height: 400px;
      width: 100%;
      margin-bottom: 40px;
    }

    .project-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: transform 0.9s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    .project-title {
      color: #1a1a22;
      font-family: "Unbounded", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 24px 0;
      padding: 0;
      text-align: left;
    }

    .project-description {
      color: #1a1a22;
      font-family: "Nunito_Sans", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.36px;
      margin: 0;
      padding: 0;
      text-align: left;
    }

    .project-link {
      font-size: 20px;
      font-family: "Nunito_Sans", sans-serif;
      align-self: start;
      text-decoration: none;
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 12px 0;
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .portfolio-container {
    gap: 160px;
    padding: 112px 0 0 0;
  }
}

@media screen and (max-width: 1000px) {
  .portfolio-container {
    gap: 40px;
  }
}

@media screen and (max-width: 550px) {
  .portfolio-container {
    grid-template-columns: 1fr;
    padding: 70px 0;

    .heading-container {
      margin-bottom: 40px;
    }

    .items-container {
      gap: 60px;
    }
  }
}
