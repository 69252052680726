.section {
  padding: 64px 14px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #1a1a22;
  gap: 40px;

  .container {
    gap: 24px;

    .header {
      font-family: "Unbounded", sans-serif;
      font-size: 40px;
      font-weight: 300;
      line-height: 47.12px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 0;
      padding: 0;
    }

    .header-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 40px;

      .text {
        margin: 0;
      }
    }

    .text {
      font-family: "Nunito", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-bottom: 40px;
    }

    &:nth-child(2) {
      .text {
        font-size: 24px;
        font-weight: 300;
        line-height: 32.74px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    .images-container {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .image-container {
        width: 100%;
        height: 130px;
        position: relative;

        &:nth-child(3) .image {
          object-position: 0 -60px;
        }
      }

      .dots-image--768 {
        display: none;
      }

      .dots-image--1440 {
        display: none;
      }

      .dots-image--mobile {
        position: absolute;
        top: 0;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 14px;
      }
    }

    .icons-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 52px;

      .icon-container {
        display: flex;
        flex-direction: column;
        font-size: 54px;
        gap: 24px;

        .text {
          font-family: "Unbounded", sans-serif;
          font-size: 24px;
          font-weight: 400;
          line-height: 29.76px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;
    gap: 64px;

    .container {
      .header {
        max-width: 600px;
      }

      .images-container {
        flex-direction: row;

        .image-container {
          height: 370px;

          &:nth-child(3) .image {
            object-position: 50% 50%;
          }
        }

        .dots-image--mobile {
          display: none;
        }

        .dots-image--768 {
          display: block;
        }
      }

      .icons-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 52px;

        .icon-container {
          &:last-child {
            grid-column: 1/-1;
          }

          .text {
            margin: 0;
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .section {
    .container:last-child {
      .header-container {
        display: grid;
        grid-template-columns: 10fr 11fr;
        gap: 10px;
        margin-bottom: 40px;

        .text {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1120px) {
  .section .container .header-container {
    gap: 50px;
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;

    .container {
      .images-container .image-container {
        .dots-image--768 {
          display: none;
        }

        .dots-image--1440 {
          display: block;
        }
      }

      &:first-child {
        .header-container {
          grid-template-columns: 3fr 2fr;
          gap: 50px;
          margin-bottom: 60px;
        }

        .text {
          font-size: 24px;
          line-height: 30px;
        }
      }

      &:last-child {
        .header {
          max-width: 100%;
        }
        .header-container {
          grid-template-columns: 1fr;
          gap: 24px;
        }

        .text {
          font-size: 24px;
          line-height: 30px;
        }
      }

      .icons-list {
        grid-template-columns: repeat(5, 1fr);

        .icon-container {
          .text {
            font-size: 14px;
            font-weight: 300;
            line-height: 20.8px;
            letter-spacing: 0.018em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          &:last-child {
            grid-column: auto;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;
  }
}
