.section {
  background-color: #f3f3f7;
  padding: 64px 14px;
  color: #1a1a22;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .header {
    margin: 0;
    padding: 0;
    font-family: "Unbounded", sans-serif;
    font-size: 36px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .blocks-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;
  }
}
