.section {
  background-color: #fff;
  padding: 64px 14px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #1e222b;
  margin-top: -1px;

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
  }

  .cards-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .card {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 31px 24px;
      background-color: #f3f3f7;
      border-radius: 16px;

      .icon {
        margin-top: 12px;
        height: 26px;
        width: 26px;
      }

      .text {
        font-family: "Unbounded", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;

    .cards-list {
      flex-direction: row;
      .card {
        flex: 1;
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;
  }
}
