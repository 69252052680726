.card-container {
  text-decoration: none;
  .card {
    background: radial-gradient(at bottom, #30343e, #30343e);
    border-radius: 16px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 357px;
    max-width: 352px;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: none !important;

    &.full {
      max-width: unset;
    }

    &:hover {
      background: radial-gradient(at bottom, #83b9f7, #2e4bad);
    }

    .title {
      font-family: "Unbounded", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 21.6px;
      letter-spacing: 0.018em;
      text-align: left;
      color: #fff;
      padding: 15px;
      text-transform: uppercase;
      text-decoration: none !important;
      margin: 10px 0 0 0;
    }

    .image-container {
      padding: 0 32px;
      margin-bottom: -4px;

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-top-left-radius: 34px;
        border-top-right-radius: 34px;
        min-width: 288px;
      }
    }
  }
}
