.footer {
  background-color: #000;
  color: #fff;
  font-family: "Unbounded", sans-serif;
  padding: 105px 240px 105px;
  display: flex;
  justify-content: space-between;

  &__company-info {
    display: flex;
    gap: 150px;
    margin-right: 100px;

    &__column {
      display: flex;
      flex-direction: column;
      gap: 30px;
      text-align: start;
      margin-top: 20px;
    }

    &--location {
      color: #629dfc;
      font-weight: 700;
    }

    &__row {
      display: flex;
      flex-direction: column;
      gap: 5px;
      text-transform: uppercase;
      font-size: 17px;
      letter-spacing: 1px;
      font-weight: 200;

      &--numbers {
        font-family: "Nunito_sans", sans-serif;
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .footer {
    padding: 50px 40px;
  }
}

@media screen and (max-width: 1150px) {
  .footer {
    padding: 50px;
    &__company-info {
      gap: 150px;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 50px 35px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    padding: 50px 15px;
    &__company-info {
      gap: 70px;
      flex-direction: column;
      * {
        text-align: center;
      }
    }
  }
}
