.section {
  background-color: #fff;
  padding: 64px 14px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #1e222b;

  .header,
  .text,
  .title {
    margin: 0;
    padding: 0;
  }

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .blocks-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    .block {
      position: relative;
      height: 204px;

      display: flex;
      flex-direction: column;
      justify-content: end;

      &:after {
        content: "";
        background: linear-gradient(
          to bottom,
          #f3f3f766,
          rgba(255, 255, 255, 0.9)
        );
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 12px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 10;
        padding: 24px 14px;

        .title {
          font-family: "Unbounded", sans-serif;
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.018em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #1d358a;
        }

        .text {
          font-family: "Nunito", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18.2px;
          letter-spacing: 0.018em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }

      .image {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
        object-position: center;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;

    .blocks-list {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;

    .blocks-list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;
  }
}
