.container {
  position: relative;
  background-color: #181820;
  overflow: hidden;
  height: 100vh;

  .circle {
    position: absolute;
    left: -25%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &--1 {
      width: 590px;
      height: 520px;
      z-index: 8;
      background: radial-gradient(ellipse, #fefefe 90%, transparent 85%);
      filter: blur(100px);
    }
    &--2 {
      width: 1210px;
      height: 1820px;
      z-index: 7;
      background: radial-gradient(ellipse, #446df3 -10%, transparent 70%);
      filter: blur(50px);
    }
    &--3 {
      width: 1250px;
      height: 2170px;
      z-index: 6;
      background: radial-gradient(ellipse, #182347 40%, transparent 70%);
    }
    &--4 {
      width: calc(100vw + 25%);
      height: 1270px;
      z-index: 5;
      background: radial-gradient(#1b1d2c 40%, transparent 80%);
    }
  }

  .noise {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
  }

  .glow-circle {
    &-container {
      margin: 20rem;
      position: absolute;
      left: 24%;
      top: -690px;
    }
    width: 10px;
    height: 10px;
    background: #fefefe;
    border-radius: 50%;
    position: relative;
    top: 117px;
    left: 167px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      height: 450px;
      border-radius: 50%;
      box-shadow:
        0 0 100px 150px #fefefe,
        0 0 250px 300px #446df3,
        0 0 300px 350px #0632c2,
        0 0 400px 500px #181820;
    }
  }

  .heading,
  .back-button-container {
    display: flex;
    flex-direction: column;
    z-index: 90;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 240px * 2);

    .header {
      &--primary {
        color: #fff;
        font-size: 90px;
        font-weight: 600;
        font-family: "Unbounded", sans-serif;
        line-height: 111px;
        letter-spacing: 1.8px;
        margin: 0;
      }

      &--secondary {
        background-image: linear-gradient(to right top, #a0d1f4, #4383ff 40%);
        color: transparent;
        background-clip: text;
        font-size: 90px;
        font-weight: 300;
        font-family: "Unbounded", sans-serif;
        line-height: 111px;
        letter-spacing: 1.8px;
        align-self: flex-end;
        margin: 0;
      }
    }
  }

  .back-button-container {
    top: 25%;
    display: flex;
    align-items: start;
  }

  .back-button {
    display: flex;
    gap: 10px;
    cursor: pointer;

    span {
      font-family: "Nunito", sans-serif;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.018em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    img {
      width: 24px;
    }
  }
}

@media screen and (min-width: 300px) {
  .container {
    .heading {
      z-index: 90;
    }

    .back-button-container {
      top: 18%;
      width: calc(100% - 14px * 2);

      span {
        font-size: 20px;
      }
    }

    .heading {
      width: calc(100% - 51px * 2);
      top: 55%;
    }

    .heading,
    .back-button-container {
      .header {
        &--primary {
          font-size: 54px;
          line-height: 67px;
        }

        &--secondary {
          font-size: 54px;
          line-height: 67px;
          margin-left: 40px;
        }
      }
    }

    .heading--sm {
      width: calc(100vw - 28px);
      padding: 0 14px;
      .header {
        &--primary {
          font-size: 32px;
          line-height: 47px;
        }

        &--secondary {
          font-size: 32px;
          line-height: 47px;
          margin-left: 10px;
        }
      }
    }

    .heading--md {
      width: calc(100vw - 28px);
      padding: 0 14px;
      .header {
        &--primary {
          font-size: 36px;
          line-height: 47px;
        }

        &--secondary {
          font-size: 36px;
          line-height: 47px;
          margin-left: 30px;
        }
      }
    }

    .circle {
      left: -50%;
      z-index: 1;
      &--1 {
        width: 200px;
        height: 600px;
      }
      &--2 {
        width: 510px;
        height: 900px;
      }
      &--3 {
        width: 650px;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 430px) {
  .container {
    .heading,
    .back-button-container {
      .header {
        &--primary {
          font-size: 54px;
          line-height: 67px;
        }

        &--secondary {
          font-size: 54px;
          line-height: 67px;
          margin-left: 40px;
        }
      }
    }

    .heading--sm {
      .header {
        &--primary {
          font-size: 36px;
          line-height: 50px;
        }

        &--secondary {
          font-size: 36px;
          line-height: 50px;
          margin-left: 20px;
        }
      }
    }

    .heading--md {
      .header {
        &--primary {
          font-size: 42px;
          line-height: 50px;
        }

        &--secondary {
          font-size: 42px;
          line-height: 50px;
          margin-left: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .container {
    .circle {
      left: -50%;
      z-index: 1;
      &--1 {
        width: 350px;
        height: 600px;
      }
      &--2 {
        width: 710px;
        height: 1400px;
      }
      &--3 {
        width: 900px;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    .heading {
      z-index: 90;
    }

    .back-button-container {
      top: 18%;
      width: calc(100% - 40px * 2);

      span {
        font-size: 20px;
      }
    }

    .heading {
      width: calc(100% - 51px * 2);
      top: 55%;
    }

    .heading,
    .back-button-container {
      .header {
        &--primary {
          font-size: 64px;
          line-height: 79px;
        }

        &--secondary {
          font-size: 64px;
          line-height: 79px;
          margin-left: 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .container {
    .circle {
      left: -50%;
      z-index: 1;
      &--1 {
        width: 480px;
        height: 600px;
      }
      &--2 {
        width: 910px;
        height: 1500px;
      }
      &--3 {
        width: 1200px;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .container {
    .circle {
      left: -45%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .container {
    .circle {
      left: -40%;
    }
  }
}

@media screen and (min-width: 1300px) {
  .container {
    .heading,
    .back-button-container {
      width: calc(100% - 51px * 2);
    }

    .heading,
    .back-button-container {
      .header {
        &--primary {
          font-size: 90px;
          line-height: 111px;
        }

        &--secondary {
          font-size: 90px;
          line-height: 111px;
        }
      }
    }

    .circle {
      left: -45%;
      z-index: 1;
      &--1 {
        width: 590px;
      }
      &--2 {
        width: 1110px;
      }
      &--3 {
        width: 1150px;
      }
      &--4 {
        width: calc(100vw + 65%);
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .container {
    .heading {
      width: calc(100% - 350px * 2);
    }

    .back-button-container {
      width: calc(100% - 240px * 2);
    }

    .circle {
      left: -25%;
      &--1 {
        width: 590px;
      }
      &--2 {
        width: 1210px;
      }
      &--3 {
        width: 1250px;
      }
      &--4 {
        width: calc(100vw + 25%);
      }
    }
  }
}
