.about-us-container {
  display: flex;
  padding: 0;
  gap: 40px;

  .heading-container {
    flex: 1;
    padding: 0 36px 36px 36px;
    margin: 0;
    transition: all 0.6s;
    position: relative;
    display: flex;

    .heading-primary {
      font-weight: 300;
      font-size: 38px;
      text-align: left;
      color: #1a1a22;
      font-family: "Unbounded", sans-serif;
      line-height: 47px;
      font-style: normal;
      z-index: 100;
    }

    .hover-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 16px;
      background: radial-gradient(circle at 30% 82%, #83b9f7 2%, #2e4bad 90%);
      opacity: 0;
      transition: all 1s;

      .connect-button {
        border-radius: 4px;
        background: #fff;
        padding: 18px 36px;
        color: #83b9f7;
        text-align: center;
        font-family: "Nunito_Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-transform: uppercase;
        border: none;
        align-self: start;
        cursor: pointer;
        z-index: 101;
        position: absolute;
        bottom: 36px;
        left: 36px;
        text-decoration: none;
        display: none;
      }
    }

    &:hover {
      border-radius: 16px;
      padding-top: 36px;
      .connect-button {
        display: block;
      }

      .heading-primary {
        color: #fff;
      }

      .hover-background {
        opacity: 1;
      }
    }
  }

  .blocks-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & * {
      z-index: 3;
    }

    .block {
      background-color: #1e222b;
      padding: 36px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      position: relative;

      &-background,
      &-background--blue-container,
      &-background--blue {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0.8;
      }

      &-background--blue-container {
        overflow: hidden;
      }

      &-background {
        opacity: 1;
        transition: all 0.6s;
      }

      &-background--blue {
        opacity: 0;
        transition:
          opacity 0.5s,
          transform 0.6s ease-in-out;
        &--1 {
          transform: scale(4.5) translateX(50px) translateY(55px) rotate(2deg);
        }
        &--2 {
          transform: scale(4.5) translateX(50px) translateY(20px) rotate(2deg);
        }
        &--3 {
          transform: scale(4.5) translateX(50px) translateY(-5px) rotate(2deg);
        }
      }

      &-body {
        color: #fff;
        font-family: "Nunito_Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: left;
        z-index: 3;
      }

      &-header {
        &-container {
          z-index: 100 !important;
        }

        color: #fff;
        font-family: "Unbounded", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        padding: 0;
        margin: 0;
      }

      &:hover {
        .block-background {
          opacity: 0;
        }
        .block-background--blue {
          opacity: 1;

          &--1 {
            transform: scale(4.5) translateX(40px) translateY(75px) rotate(5deg)
              scale(1.35);
          }

          &--2 {
            transform: scale(4.5) translateX(40px) translateY(40px) rotate(5deg)
              scale(1.35);
          }
          &--3 {
            transform: scale(4.5) translateX(40px) translateY(5px) rotate(5deg)
              scale(1.35);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .about-us-container {
    display: flex;
    padding: 0;
  }
}

@media screen and (max-width: 1000px) {
  .about-us-container {
    flex-direction: column;

    .heading-container {
      &:hover {
        padding-bottom: 140px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-us-container {
    flex-direction: column;

    .blocks-container {
      .block {
        &-background--blue-container {
          overflow: hidden;
        }
        &-background--blue {
          &--1 {
            transform: scale(3) translateX(50px) translateY(60px) rotate(2deg);
          }
          &--2 {
            transform: scale(3) translateX(50px) translateY(-20px) rotate(2deg);
          }
          &--3 {
            transform: scale(3) translateX(50px) translateY(-100px) rotate(2deg);
          }
        }

        &:hover {
          .block-background {
            opacity: 0;
          }
          .block-background--blue {
            opacity: 1;

            &--1 {
              transform: scale(3) translateX(40px) translateY(70px) rotate(5deg)
                scale(1.35);
            }

            &--2 {
              transform: scale(3) translateX(40px) translateY(-10px)
                rotate(5deg) scale(1.35);
            }
            &--3 {
              transform: scale(3) translateX(40px) translateY(-90px)
                rotate(5deg) scale(1.35);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .about-us-container {
    .heading-container {
      .heading-primary {
        font-size: 34px;
      }
    }
  }
}

@keyframes block-animation {
  0% {
  }
}
