.section {
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
  }

  .cards-list {
    display: flex;
    height: 357px;
    justify-content: space-between;
    gap: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 70px;

    &.grid--2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 36px;
    }
  }
}
