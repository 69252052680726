.container {
  padding: 112px 240px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 100px;
  row-gap: 16px;
  align-items: start;

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & * {
      color: #1a1a22;
    }

    .heading {
      .header {
        font-family: "Unbounded", sans-serif;
        font-size: 40px;
        letter-spacing: 1.2px;
        font-weight: 700;
        line-height: 47.12px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 0;
        display: inline;
      }

      .subheader {
        font-family: "Unbounded", sans-serif;
        font-size: 40px;
        font-weight: 300;
        line-height: 47.12px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-transform: lowercase;
      }
    }
    .description {
      margin: 0;
      font-family: "Nunito", sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: 0.1em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .labels-container {
    padding: 36px;
    border-radius: 24px;
    background: linear-gradient(to top, #1b2029, #22336d);
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    //todo add animation
    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4465d2;
      border-radius: 8px;
      padding: 8px 16px;
      font-family: "Nunito", sans-serif;
      font-size: 14px;
      font-weight: 300;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .button {
    justify-self: start;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 4px;
    background: linear-gradient(203deg, #4383ff -4.85%, #a0d1f4 152.7%);
    padding: 18px 36px;
    color: #fff;
    text-align: center;
    font-family: "Nunito_Sans", sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    border: none;
    align-self: start;
    text-decoration: none;
  }
}

@media screen and (min-width: 300px) {
  .container {
    padding: 112px 14px;
    grid-template-columns: 1fr;
    gap: 64px;

    .labels-container {
      gap: 12px;
    }

    .text-container {
      .header,
      .subheader {
        font-size: 32px !important;
      }
      .description {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.018em;
      }
    }
  }
}

@media screen and (min-width: 410px) {
  .container {
    .text-container {
      .description {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.018em;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    padding: 112px 40px;
  }
}

@media screen and (min-width: 1300px) {
  .container {
    padding: 112px 128px;
    grid-template-columns: 7fr 5fr;
    column-gap: 100px;

    .labels-container {
      gap: 16px;
    }

    .text-container {
      .description {
        font-size: 20px;
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .container {
    padding: 112px 240px;
    grid-template-columns: 12fr 6fr;
    gap: 110px;

    .labels-container {
      gap: 16px;
    }

    .text-container {
      .description {
        font-size: 20px;
      }

      .heading {
        max-width: 600px;
      }
    }
  }
}
