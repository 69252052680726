.app-description-container {
  background-color: #fff;
  padding: 112px 240px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 250px;

  .left {
    display: flex;
    flex-direction: column;
    gap: 64px;

    * {
      font-family: "Unbounded", sans-serif;
    }

    .list-block {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__header {
        margin: 0;
        font-size: 24px;
        font-weight: 300;
        line-height: 29.76px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &--sm {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }

      .list__item {
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    .icons-list-block {
      display: flex;
      flex-direction: column;
      gap: 28px;

      &__header {
        margin: 0;
        font-size: 24px;
        font-weight: 300;
        line-height: 29.76px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }

      .list__item {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .icon {
          font-size: 32px;
        }

        .text {
          font-family: "Nunito", sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 20.8px;
          letter-spacing: 0.018em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }

    .paragraph {
      font-family: "Nunito", sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 20.8px;
      letter-spacing: 0.018em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .right {
    position: relative;
    .image-container {
      position: sticky;
      top: 30px;
      left: 0;
      width: 100%;
      border-radius: 24px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .text-container {
        z-index: 3;
        color: #fff;
        padding: 72px 42px;
        width: calc(100% - 42px * 2);
        height: calc(100% - 72px * 2);
        background: linear-gradient(
          150deg,
          #2e4bad 1%,
          #1e222b 30%,
          #00000099 150%
        );
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 85px;

        .title {
          font-family: "Unbounded", sans-serif;
          font-size: 28px;
          font-weight: 400;
          line-height: 29.76px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          &--blue {
            background: -webkit-linear-gradient(#a0d1f4, #4383ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .body {
          font-family: "Nunito", sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 20.8px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }
  }
}

@media screen and (min-width: 300px) {
  .app-description-container {
    padding: 112px 14px;
    gap: 40px;
    grid-template-columns: 1fr;

    .list-block__list {
      padding-left: 26px;
    }

    .left .list-block .list__item,
    .left .icons-list-block .list__item .text {
      font-size: 16px;
      font-weight: 300;
    }

    .right .image-container .text-container .title {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 768px) {
  .app-description-container {
    padding: 112px 40px;
    gap: 40px;
    grid-template-columns: 1fr;

    .list-block__list {
      padding-left: 26px;
    }

    .left .list-block .list__item,
    .left .icons-list-block .list__item .text {
      font-size: 16px;
      font-weight: 300;
    }

    .right .image-container .text-container .title {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 1300px) {
  .app-description-container {
    padding: 112px 128px;
    gap: 180px;
    grid-template-columns: 3fr 2fr;
  }
}

@media screen and (min-width: 1910px) {
  .app-description-container {
    padding: 112px 240px;
    gap: 180px;
    grid-template-columns: 3fr 2fr;

    .left {
      gap: 44px;

      .list-block__list {
        gap: 20px !important;
      }
    }
  }
}
