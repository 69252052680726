.container {
  background-color: #1e222b;
  padding: 64px 14px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    margin: 0;
    padding: 0;
    font-family: "Nunito", sans-serif;
    font-size: 48px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .blocks-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    .block {
      background-color: #30343e;
      min-height: calc(139px - 24px * 2);
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 24px 14px;
      gap: 8px;

      &__title,
      &__text {
        padding: 0;
        margin: 0;
      }

      &__title {
        font-family: "Unbounded", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      &__text {
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .container {
    padding: 112px 40px;

    .blocks-list {
      display: grid;
      grid-template-columns: repeat(20, 1fr);

      .block {
        &:nth-child(1) {
          grid-column: 1/12;
          grid-row: 1;
        }

        &:nth-child(2) {
          grid-column: 12/-1;
          grid-row: 1;
        }

        &:nth-child(3) {
          grid-column: 1/10;
          grid-row: 2;
        }
        &:nth-child(4) {
          grid-column: 10/19;
          grid-row: 2;
        }
        &:nth-child(5) {
          grid-column: 1/12;
          grid-row: 3;
        }
      }
    }
  }
}

@media screen and (min-width: 950px) {
  .container {
    .blocks-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      .block {
        &:nth-child(1) {
          grid-column: 1/3;
          grid-row: 1;
        }

        &:nth-child(2) {
          grid-column: 3/5;
          grid-row: 1;
        }

        &:nth-child(3) {
          grid-column: 5/-1;
          grid-row: 1;
        }
        &:nth-child(4) {
          grid-column: 1/4;
          grid-row: 2;
        }
        &:nth-child(5) {
          grid-column: 4/-1;
          grid-row: 2;
        }
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .container {
    padding: 112px 128px;

    .blocks-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .block {
        &:nth-child(1) {
          grid-column: auto;
        }

        &:nth-child(2) {
          grid-column: auto;
        }

        &:nth-child(3) {
          grid-column: auto;
        }
        &:nth-child(4) {
          grid-column: auto;
          grid-row: 1;
        }
        &:nth-child(5) {
          grid-column: auto;
          grid-row: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .container {
    padding: 112px 240px;
  }
}
