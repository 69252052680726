.container {
  display: flex;
  flex-direction: column;
  gap: 112px;

  .heading-container {
    display: flex;
    justify-content: space-between;

    .header {
      max-width: 450px;
      text-align: left;
      color: #1a1a22;
      font-family: "Unbounded", sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      padding: 0;
      margin: 0;
    }

    .subheader {
      max-width: 500px;
      text-align: left;
      padding: 0;
      margin: 0;
      color: #1a1a22;
      font-family: "Nunito_Sans", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.36px;
    }
  }

  .stages-list {
    display: flex;
    justify-content: space-between;
  }
}

.stage-item {
  &__icon {
    font-size: 54px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 24px;
    max-width: 250px;

    * {
      text-align: left;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    color: #1a1a22;
    font-family: "Unbounded", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__description {
    color: #1a1a22;
    font-family: "Nunito_Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.288px;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 1000px) {
  .stages-list {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    .stage-item {
      &__container {
        max-width: unset;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .heading-container {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .subheader {
        max-width: unset;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    gap: 60px;
    .stages-list {
      display: grid !important;
      grid-template-columns: 1fr;
      gap: 40px;

      .stage-item {
        &__container {
          max-width: unset;
        }
      }
    }
  }
}
