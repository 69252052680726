.section {
  background-color: #fff;
  color: #1e222b;
  padding: 64px 0;

  .header,
  .text {
    margin: 0;
    padding: 0 14px;
  }

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .text {
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 32.74px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 24px;
    margin-bottom: 60px;
  }

  .images-block {
    display: flex;
    gap: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 70px;
    margin-left: 14px;
    padding-right: 14px;
    color: #fff;

    .image-container {
      position: relative;

      .text {
        position: absolute;
        width: calc(100% - 20px);
        top: 0;
        left: 10px;
        z-index: 10;
        padding: 0;
        font-family: "Unbounded", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.6px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        text-transform: uppercase;

        &:not(:first-child) {
          left: 22px;
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 0;

    .header,
    .text {
      padding: 0 40px;
    }

    .images-block {
      margin-left: 40px;
      .image-container:last-child {
        margin-right: 40px;
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .section {
    .header,
    .text {
      padding: 0 128px;
    }

    .images-block {
      margin-left: 128px;
      .image-container:last-child {
        margin-right: 128px;
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 0;

    .header,
    .text {
      padding: 0 240px;
    }

    .images-block {
      margin-left: 240px;

      .image-container:last-child {
        margin-right: 240px;
      }
    }
  }
}
