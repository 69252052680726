.image-section {
  display: inline;
  height: 420px;
  position: relative;
  z-index: -2;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1e222b;
    opacity: 0.5;
    z-index: 1;
  }

  .image-container {
    background-image: url("../../media/case-study/1551-bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    padding: 120px 240px;
    justify-content: center;
    align-items: center;
    gap: 38px;
    min-height: 320px;

    .logo {
      z-index: 1;
    }

    .text {
      z-index: 1;
      max-width: 650px;
      font-family: "Gotham Pro", sans-serif;
      font-size: 52px;
      font-weight: 400;
      line-height: 49.77px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
      text-transform: uppercase;
    }
  }
}

@media screen and (min-width: 300px) {
  .image-section {
    .image-container {
      padding: 80px 20px;
      flex-direction: column;
      .text {
        text-align: center;
        font-size: 36px;
        line-height: 34.45px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .image-section {
    .image-container {
      padding: 80px 50px;
      flex-direction: row;

      .logo {
        padding: 0 35px;
      }

      .text {
        text-align: start;
        font-size: 36px;
        line-height: 34.45px;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .image-section {
    .image-container {
      padding: 0 50px;
      min-height: 0;

      .text {
        padding: 150px 0;
        font-size: 52px;
        line-height: 49.77px;
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .image-section {
    .image-container {
      gap: 72px;
    }
  }
}
