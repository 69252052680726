.container {
  .header {
    max-width: 450px;
    color: #1a1a22;
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    margin-bottom: 96px;
  }

  .blocks-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .blocks-container {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    .header {
      margin: 0 0 60px 0;
    }
  }
}
