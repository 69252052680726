.section {
  background-color: #fff;
  padding: 64px 14px;

  .card {
    background-color: #1e222b;
    padding: 64px 40px;
    border-radius: 24px;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;

    .left {
      .text-block {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .header-container {
          display: flex;
          flex-direction: column;

          .header {
            font-family: "Unbounded", sans-serif;
            font-size: 40px;
            font-weight: 300;
            line-height: 49.6px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin: 0;
            padding: 0;

            .text-arrow {
              margin-left: 0;
              transform: rotate(90deg);
              display: none;
            }
          }
          .arrow {
            align-self: start;
            transform: rotate(90deg);
            margin-top: 48px;
          }
        }

        .info-container {
          display: flex;
          flex-direction: column;
          gap: 36px;
          * {
            padding: 0;
            margin: 0;
          }

          .message {
            font-family: "Unbounded", sans-serif;
            font-size: 20px;
            font-weight: 300;
            line-height: 24.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          .info-text {
            font-family: "Nunito", sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          .email {
            font-family: "Unbounded", sans-serif;
            font-size: 17px;
            font-weight: 300;
            line-height: 20.4px;
            letter-spacing: 0.018em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            text-transform: uppercase;
            background-image: linear-gradient(#4383ff, #a0d1f4);
            color: transparent;
            background-clip: text;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 64px;

      .chosen-date-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 36px;

        .text-container {
          cursor: pointer;
        }

        .text-container span,
        .date {
          font-family: "Nunito", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.018em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        .date {
          font-family: "Unbounded", sans-serif;
          font-size: 18px;
          font-weight: 900;
          line-height: 24.8px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        .text-container {
          display: flex;
          align-items: center;
          gap: 0.8rem;
        }
      }

      .date-input {
        width: 100%;
        position: relative;
        background-color: transparent;
        border: none;
        padding-bottom: 15px;
        border-bottom: 1px solid #eeedf2;
        font-family: "Unbounded", sans-serif;
        font-size: 18px;
        color: #fff;
        transition: all 0.3s;

        &::placeholder {
          font-family: "Unbounded", sans-serif;
          font-weight: 300;
          color: #fff;
          letter-spacing: 1px;
          overflow-wrap: break-word;
          flex-wrap: wrap;
        }

        &:focus {
          outline: none;
          border-color: #415dbd;
        }

        &:last-child::placeholder {
          white-space: normal;
        }
      }

      .button-container {
        max-width: 80%;
        margin-left: 10%;

        .button {
          justify-self: start;
          font-weight: 400;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 30px;
          cursor: pointer;
          transition: all 0.3s;
          border-radius: 4px;
          background: linear-gradient(203deg, #4383ff -4.85%, #a0d1f4 152.7%);
          padding: 18px 36px;
          color: #fff;
          text-align: center;
          font-family: "Nunito_Sans", sans-serif;
          font-size: 16px;
          line-height: 25px;
          text-transform: uppercase;
          border: none;
          align-self: start;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (min-width: 440px) {
  .section {
    background-color: #fff;
    padding: 64px 14px;

    .card {
      background-color: #1e222b;
      padding: 64px 40px;
      border-radius: 24px;
      color: #fff;

      .left {
        .header {
          img {
            margin-left: 0;
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .section {
    padding: 112px 40px;

    .card .right .button-container {
      max-width: 90%;
      margin-left: 5%;
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    .card {
      .left {
        .text-block {
          flex-direction: row;
          gap: 24px;
          .header-container {
            .arrow {
              margin-top: 28px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .section .card {
    overflow: hidden;

    * {
      z-index: 100;
    }

    .circle {
      height: 750px;
      width: 1005px;
      position: absolute;
      background: radial-gradient(#227aff -70%, #1e222b 70%);
      top: -30%;
      left: -55%;
      z-index: 10;
    }
  }

  .form {
    background-color: red !important;
  }

  .section .card .left .text-block {
    gap: 80px;
  }
  .section .card .right .button-container {
    max-width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;

    .card {
      flex-direction: row;
      gap: 96px;
      padding: 80px 128px;

      .circle {
        height: 750px;
        width: 1005px;
        position: absolute;
        background: radial-gradient(#227aff -70%, #1e222b 70%);
        top: -30%;
        left: -30%;
        z-index: 10;
      }

      .left {
        flex: 1;
        .text-block {
          flex-direction: column;
          height: 100%;
          .header-container {
            .text-arrow {
              display: inline !important;
              transform: rotate(0deg) !important;
              margin-left: 1rem !important;
            }
            .arrow {
              display: none;
            }
          }

          .info-container {
            justify-content: space-between;
            flex: 1;
          }
        }
      }

      .right {
        flex: 1;
        margin-top: 0;
        .date-input {
          margin-top: 0;
        }

        .button-container {
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;

    .card {
      flex-direction: row;
      gap: 124px;

      .left {
        flex: 1;
        .text-block {
          flex-direction: column;
        }
      }

      .right {
        flex: 1;
        .date-input {
          margin-top: 0;
        }

        .button-container {
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
