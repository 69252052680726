.services-container {
  background-color: #fff;
  margin-top: -40px;
  padding: 64px 14px;
}

@media screen and (min-width: 700px) {
  .services-container {
    padding: 112px 40px;
  }
}

@media screen and (min-width: 1300px) {
  .services-container {
    padding: 112px 128px;
  }
}

@media screen and (min-width: 1910px) {
  .services-container {
    padding: 112px 240px;
  }
}
