.section {
  background-color: #fff;
  padding: 64px 14px;
  display: flex;
  flex-direction: column;
  color: #1e222b;
  gap: 36px;
  justify-content: space-between;

  .header,
  .text,
  .title {
    margin: 0;
    padding: 0;
  }

  .dots--768,
  .dots--1000,
  .dots--1440,
  .dots--1920 {
    display: none;
  }

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 24px;
  }

  .left > .text {
    margin-bottom: 56px;
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 32.74px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .icons-list-container {
    display: flex;
    gap: 56px;

    .progress-bar {
      padding: 37px 0;
      height: 960px;
    }

    .icons-list {
      display: flex;
      flex-direction: column;
      gap: 52px;

      .list-item {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .icon {
          font-size: 54px;
        }

        .title {
          font-family: "Unbounded", sans-serif;
          font-size: 28px;
          line-height: 29px;
          font-weight: 400;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          flex: 1;
        }

        .text {
          font-family: "Nunito", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.8px;
          letter-spacing: 0.018em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }
  }

  .right {
    position: relative;
    display: flex;

    &:after {
      content: "";
      background: linear-gradient(
        to bottom,
        #f3f3f766 50%,
        rgba(255, 255, 255, 0.7)
      );
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 12px;
    }

    .image {
      width: 100%;
      height: 490px;
      object-fit: cover;
      border-radius: 12px;
    }

    .dots {
      position: absolute;
      height: 100%;
      z-index: 10;
      right: 0;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 510px) {
  .section .icons-list-container .progress-bar {
    height: 910px;
  }
}

@media screen and (min-width: 580px) {
  .section .icons-list-container .progress-bar {
    height: 870px;
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;
    flex-direction: row;

    .icons-list-container .progress-bar {
      height: 1030px;
    }

    .dots--430 {
      display: none;
    }

    .dots--768 {
      display: block;
    }

    .right {
      .image {
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }

      .dots {
        position: absolute;
        z-index: 10;
        right: 0;
        width: 100%;
        height: auto !important;
        bottom: 0;
        object-fit: contain;
      }
    }
  }
}

@media screen and (min-width: 890px) {
  .section {
    .dots--768 {
      display: none;
    }

    .dots--1000 {
      display: block;
    }

    .icons-list-container .progress-bar {
      height: 980px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .section .right {
    max-width: 432px;
  }
}

@media screen and (min-width: 1175px) {
  .section .icons-list-container .progress-bar {
    height: 920px;
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;
    .dots--1000 {
      display: none;
    }

    .dots--1440 {
      display: block;
    }
    .right {
      max-width: 564px;
    }
  }
}

@media screen and (min-width: 1395px) {
  .section .icons-list-container .progress-bar {
    height: 890px;
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;

    .dots--1440 {
      display: none;
    }

    .dots--1920 {
      display: block;
    }

    .right {
      max-width: 692px;
    }
  }
}
