.other-projects-container {
  background-color: #fff;
  padding: 112px 240px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  .title {
    margin: 0;
    font-family: "Unbounded", sans-serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .cards-list {
    display: flex;
    gap: 64px;
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    padding-bottom: 24px;

    a {
      text-decoration: none;
    }

    .card {
      background: radial-gradient(at bottom, #30343e, #30343e);
      border-radius: 16px;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 357px;
      max-width: 352px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: radial-gradient(at bottom, #83b9f7, #2e4bad);
      }

      .title {
        font-family: "Unbounded", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.6px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
        padding: 20px;
        text-transform: uppercase;
      }

      .image-container {
        padding: 0 32px;
        margin-bottom: -4px;
      }
    }
  }
}

@media screen and (min-width: 300px) {
  .other-projects-container {
    padding: 112px 14px;
    .cards-list {
      gap: 12px;
    }
  }
}

@media screen and (min-width: 768px) {
  .other-projects-container {
    padding: 112px 40px;
  }
}

@media screen and (min-width: 1000px) {
  .other-projects-container {
    padding: 112px 40px;
  }
}

@media screen and (min-width: 1300px) {
  .other-projects-container {
    padding: 112px 128px;
  }
}

@media screen and (min-width: 1910px) {
  .other-projects-container {
    padding: 112px 240px;
  }
}
