.section {
  background-color: #1e222b;
  padding: 64px 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #fff;

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding: 0;
  }

  .blocks-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .block {
      display: flex;
      align-items: end;
      height: 74px;
      padding: 24px 14px;
      background-color: #30343e;
      border-radius: 16px;
      font-family: "Unbounded", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.018em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(7) {
        grid-column: 1 / -1;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;

    .blocks-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      .block {
        &:nth-child(1) {
          grid-column: span 7;
        }

        &:nth-child(2) {
          grid-column: span 5;
        }

        &:nth-child(3) {
          grid-column: span 6;
        }

        &:nth-child(4) {
          grid-column: span 6;
        }

        &:nth-child(5) {
          grid-column: span 7;
        }

        &:nth-child(6) {
          grid-column: span 5;
        }

        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          grid-column: span 4; // 33% of 12 columns
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .section {
    padding: 112px 128px 150px 128px;

    .blocks-container {
      display: grid;
      grid-template-columns: repeat(33, 1fr);

      .block {
        &:nth-child(1) {
          grid-column: span 13;
        }

        &:nth-child(2) {
          grid-column: span 10;
        }

        &:nth-child(3) {
          grid-column: span 10;
        }

        &:nth-child(4) {
          grid-column: span 11;
        }

        &:nth-child(5) {
          grid-column: span 11;
        }

        &:nth-child(6) {
          grid-column: span 11;
        }

        &:nth-child(7) {
          grid-column: span 10;
        }

        &:nth-child(8) {
          grid-column: span 10;
        }

        &:nth-child(9) {
          grid-column: span 13;
        }
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px 150px 128px;
  }
}

@media screen and (min-width: 1400px) {
  .section .blocks-container {
    display: grid;
    grid-template-columns: repeat(60, 1fr);

    .block {
      &:nth-child(1) {
        grid-column: span 26;
      }

      &:nth-child(2) {
        grid-column: span 16;
      }

      &:nth-child(3) {
        grid-column: span 18;
      }

      &:nth-child(4) {
        grid-column: span 19;
      }

      &:nth-child(5) {
        grid-column: span 19;
      }

      &:nth-child(6) {
        grid-column: span 19;
      }

      &:nth-child(7) {
        grid-column: span 17;
      }

      &:nth-child(8) {
        grid-column: span 15;
      }

      &:nth-child(9) {
        grid-column: span 19;
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px 112px 290px;

    .blocks-container {
      display: grid;
      grid-template-columns: repeat(60, 1fr);

      .block {
        &:nth-child(1) {
          grid-column: span 15;
        }

        &:nth-child(2) {
          grid-column: span 15;
        }

        &:nth-child(3) {
          grid-column: span 15;
        }

        &:nth-child(4) {
          grid-column: span 15;
        }

        &:nth-child(5) {
          grid-column: span 11;
        }

        &:nth-child(6) {
          grid-column: span 11;
        }

        &:nth-child(7) {
          grid-column: span 13;
        }

        &:nth-child(8) {
          grid-column: span 12;
        }

        &:nth-child(9) {
          grid-column: span 13;
        }
      }
    }
  }
}
