// #fefefe,
// #446df3,
// #0632c2,
// #1b1d2c

.App {
  text-align: center;
  /* background-color: #040404; */
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.loading {
  background-color: red;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 100;
}

.invalid {
  position: relative;

  &::after {
    content: "!";
    font-weight: 600;
    color: #fff;
    font-size: 28px;
    position: absolute;
    font-family: "Nunito_sans", sans-serif;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 32px;
    width: 32px;
    background-color: red;
  }
}

.invalid > input {
  border-bottom-color: #ff0000 !important;
  padding-right: 40px;
}

.section--1 {
  position: relative;
  background-color: #1d212a;
  overflow: hidden;
  height: 100vh;

  .circle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -60%);
    border-radius: 50%;
    &--1 {
      width: 610px;
      height: 520px;
      z-index: 8;
      background: radial-gradient(#fefefe 10%, transparent 65%);
      filter: blur(30px);
    }
    &--2 {
      width: 810px;
      height: 820px;
      z-index: 7;
      background: radial-gradient(#446df3 10%, transparent 70%);
      filter: blur(30px);
    }
    &--3 {
      width: 1950px;
      height: 1670px;
      z-index: 6;
      background: radial-gradient(#446df3 0%, transparent 70%);
    }

    &--4 {
      width: 2050px;
      height: 1870px;
      z-index: 5;
      background: radial-gradient(#1d212a 20%, transparent 80%);
    }
  }

  .noise {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
  }

  .glow-circle {
    &-container {
      margin: 20rem;
      position: absolute;
      left: 24%;
      top: -690px;
    }
    width: 10px;
    height: 10px;
    background: #fefefe;
    border-radius: 50%;
    position: relative;
    top: 117px;
    left: 167px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      height: 450px;
      border-radius: 50%;
      box-shadow:
        0 0 100px 150px #fefefe,
        0 0 250px 300px #446df3,
        0 0 300px 350px #0632c2,
        0 0 400px 500px #181820;
    }
  }
}

.section--white {
  background-color: #fff;
  padding: 112px 240px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.section--light-gray {
  padding: 112px 240px;
  background-color: #f3f3f7;
}

.section--dark-gray {
  padding: 112px 240px;
  background-color: #1e222b;
}

@media screen and (max-width: 1580px) {
  .section--1 {
    padding-bottom: 0;
    min-height: 100vh;
    .circle {
      &--1 {
        width: 50%;
        height: 520px;
      }
      &--2 {
        width: 150%;
        height: 1020px;
      }
      &--3 {
        width: 180%;
        height: 1270px;
      }
      &--4 {
        width: 200%;
        height: 1470px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .section--white {
    padding: 112px;
    gap: 100px;
  }

  .section--light-gray {
    padding: 112px;
  }

  .section--dark-gray {
    padding: 112px;
  }
}

@media screen and (max-width: 1150px) {
  .section--1 {
    .glow-circle {
      &-container {
        left: 8%;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .section--white {
    padding: 112px 40px;
  }

  .section--light-gray {
    padding: 112px 40px;
  }

  .section--dark-gray {
    padding: 112px 40px;
  }
}

@media screen and (max-width: 768px) {
  .section--white {
    padding: 112px 35px;
  }

  .section--light-gray {
    padding: 112px 35px;
  }

  .section--dark-gray {
    padding: 112px 35px;
  }
}

@media screen and (max-width: 650px) {
  .section--1 {
    min-height: auto;
    padding-bottom: 50px;
    .noise {
      height: 80%;
    }
    .circle {
      &--1 {
        width: 100%;
        height: 15%;
      }
      &--2 {
        width: 150%;
        height: 120%;
      }
      &--3 {
        width: 180%;
        height: 140%;
      }
      &--4 {
        width: 200%;
        height: 160%;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .section--white {
    padding: 60px 15px;
    gap: 50px;
  }

  .section--light-gray {
    padding: 60px 15px;
  }

  .section--dark-gray {
    padding: 60px 15px;
  }
}
