.container {
  display: flex;
  flex-direction: column;
  padding: 112px 0;

  .header {
    color: #1a1a22;
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    align-self: start;
    max-width: 450px;
    text-align: start;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    overflow: hidden;
    gap: 16px;
  }

  .sector-card {
    height: 560px;
    width: auto;
    position: relative;

    &__text {
      position: absolute;
      z-index: 100;
      bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 24px 12px;
      opacity: 0;
      transition: all 0.5s;

      &--title {
        font-family: "Unbounded", sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        text-align: start;
        margin: 0;
      }

      &--body {
        font-family: "Nunito_Sans", sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        text-align: left;
        margin: 0;
      }
    }

    &__overflow {
      background-color: rgba(144, 157, 174, 0.5);
      border-radius: 16px;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 10;
      transition: all 0.8s;
    }

    &__image-container {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
    }

    &:nth-child(2) {
      .sector-card__image {
        transform: scale(120%) translate(-20px, -40px);
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
      filter: grayscale(0.7) blur(0.5px);
      transition: all 0.5s;
    }

    &:hover {
      .sector-card__overflow {
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.6) 15%,
          transparent
        );
        transition: all 0.5s;
      }

      .sector-card__text {
        opacity: 1;
      }

      .sector-card__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
        filter: grayscale(0) blur(0);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 0;
    .cards-container {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: auto auto;
      gap: 16px;

      & > :nth-child(1) {
        grid-column: 1 / 4;
      }

      & > :nth-child(2) {
        grid-column: 4 / 7;
      }

      & > :nth-child(3) {
        grid-column: 1 / 3;
      }

      & > :nth-child(4) {
        grid-column: 3 / 5;
      }

      & > :nth-child(5) {
        grid-column: 5 / 7;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    padding: 0;

    .sector-card {
      &:nth-child(2) {
        .sector-card__image {
          transform: scale(120%) translate(-30px, -20px);
        }
      }
    }
    .cards-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 16px;

      & > :nth-child(1) {
        grid-column: 1 / 2;
        height: 265px;
      }

      & > :nth-child(2) {
        grid-column: 1 / 2;
        height: 265px;
      }

      & > :nth-child(3) {
        grid-column: 1 / 2;
        height: 265px;
      }

      & > :nth-child(4) {
        grid-column: 1 / 2;
        height: 265px;
      }

      & > :nth-child(5) {
        grid-column: 1 / 2;
        height: 265px;
      }
    }
  }
}
