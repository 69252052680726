body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #040404;
}

@font-face {
  font-family: "Unbounded";
  src: url("../fonts/Unbounded/Unbounded-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Nunito_Sans";
  src: url("../fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/Gotham Pro/gothampro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/Gotham Pro/gothampro_bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Nunito_Sans, Unbounded,
    "Courier New", monospace;
}
