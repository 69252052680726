.section {
  background-color: #fff;
  padding: 64px 14px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  color: #1e222b;

  .header-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .header,
  .text {
    margin: 0;
    padding: 0;
  }

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    max-width: 400px;
  }

  .text {
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 32.74px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .icons-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 52px;

    .list-item {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .icon {
        font-family: "Nunito", sans-serif;
        font-size: 54px;
        font-weight: 400;
        line-height: 70.2px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .text {
        font-family: "Unbounded", sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 29.76px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;
    .icons-list {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px 150px 128px;

    .icons-list {
      grid-template-columns: repeat(6, 1fr);

      .list-item {
        position: relative;
        .text {
          font-family: "Nunito", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.8px;
          letter-spacing: 0.018em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        &:not(:last-child) {
          &:before {
            position: absolute;
            content: "";
            background: #bbc2cd;
            height: 4px;
            width: calc(100% + 52px);
            bottom: -36px;
            left: 5px;
          }
        }

        &:after {
          position: absolute;
          content: "";
          border: 2px solid #bbc2cd;
          background: #d9d9d9;
          height: 22px;
          width: 22px;
          bottom: -48px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px 112px 290px;
  }
}
