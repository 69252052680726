.container {
  display: flex;
  gap: 160px;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: column;
    gap: 54px;
    padding: 40px 0;

    .header {
      color: #fff;
      font-family: "Unbounded", sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
      padding: 0;
      text-align: left;
    }

    .text {
      color: #fff;
      font-family: "Nunito_Sans", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 130%;
      letter-spacing: 0.36px;
      text-align: left;
    }

    .connect-button {
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 4px;
      background: linear-gradient(203deg, #4383ff -4.85%, #a0d1f4 152.7%);
      padding: 18px 36px;
      color: #fff;
      text-align: center;
      font-family: "Nunito_Sans", sans-serif;
      font-size: 16px;
      line-height: 25px;
      text-transform: uppercase;
      border: none;
      align-self: start;
      z-index: 101;

      &:hover {
        box-shadow: 0 0 15px 0 #fff;
      }
    }
  }

  .right {
    color: #1c1c1c;

    h1 {
      margin: 0;
      padding: 0;
    }

    .drop-down {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__block {
        border-bottom: 1px solid #e5e8ef;
        cursor: pointer;

        .chevron {
          transition: all 0.3s;
          &--up {
            transform: rotate(0deg);
          }
          &--down {
            transform: rotate(180deg);
          }
        }

        .drop-down__content {
          img {
            width: 100%;
            height: 153px;
            object-fit: cover;
          }

          p {
            text-align: left;
            color: #e5e8ef;
            font-family: "Nunito_Sans", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            letter-spacing: 0.288px;
          }
        }

        .top {
          display: flex;
          justify-content: space-between;
          padding: 24px 0;
        }

        &__title {
          color: #fff;
          font-family: "Unbounded", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: start;
        }

        img {
          justify-self: end;
        }
      }

      &__content {
        overflow: hidden;
        max-height: 0;
        transition: all 0.4s;

        &.visible {
          max-height: 500px;
          overflow: auto;
        }
      }
    }

    @media screen and (min-width: 1800px) {
      .services-container {
        gap: 8rem;
      }

      .services-container {
        .drop-down {
          &__block {
            .text {
              display: grid;
              grid-template-columns: 1fr 10fr 1fr;

              .drop-down__content {
                grid-column: 2;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    gap: 40px;

    .left {
      gap: 35px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    gap: 20px;

    .left {
      gap: 30px;
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    flex-direction: column;
  }
}
