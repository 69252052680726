.section {
  background-color: #1e222b;
  padding: 64px 14px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #fff;

  .header,
  .text,
  .title {
    margin: 0;
    padding: 0;
  }

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .blocks-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .block {
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #30343e;
      min-height: 120px;
      padding: 36px 14px 24px 14px;
      flex: 1;
      gap: 24px;

      .icon {
        height: 26px;
        width: 26px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .title {
        font-family: "Unbounded", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .text {
        font-family: "Nunito", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.2px;
        letter-spacing: 0.018em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;
    .blocks-container {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;
  }
}
