.question {
  &__container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 40px 32px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    cursor: pointer;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  &__title {
    color: #1a1a22;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: "Nunito_Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding: 0;
    margin: 0;
    text-align: left;

    &--blue {
      color: #4465d2;
    }
  }

  &__button {
    background-color: #f3f3f7;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    border: none;
    transition: all 0.3s;

    &--minus {
      background-color: #4465d2;
    }

    &-icon {
      height: 17px;
      width: 17px;
    }
  }

  &__body {
    margin-top: 24px;
    transition:
      max-height 0.3s ease,
      margin 0.3s ease;
    overflow: hidden;
    max-height: 500px;
    text-align: start;
    color: #616874;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: "Nunito_Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    &--hidden {
      max-height: 0;
      margin: 0;
    }
  }
}
