.section {
  background-color: #fff;
  padding: 64px 14px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #1e222b;

  .header-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &--2 {
      margin-top: 120px;
      margin-bottom: 14px;
      justify-content: space-between;

      .header {
        max-width: 558px;
      }
    }
  }

  .header,
  .text {
    margin: 0;
    padding: 0;
  }

  .text--2 {
    padding-bottom: 40px;
  }

  .header {
    font-family: "Unbounded", sans-serif;
    font-size: 38px;
    font-weight: 300;
    line-height: 47.12px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .checkmarks {
    height: 95px;
    width: 95px;
  }

  .text {
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .image-container {
    width: 100%;
    position: relative;

    .image {
      border-radius: 16px;
      height: 140px;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .dots,
    .image {
      width: 100%;
    }

    .dots--768,
    .dots--1000,
    .dots--1440,
    .dots--1920 {
      display: none;
    }

    .dots {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
}

@media screen and (min-width: 700px) {
  .section {
    padding: 112px 40px;

    .header-container--2 {
      flex-direction: row-reverse;
      gap: 35px;
    }

    .image-container {
      .image {
        height: 370px;
      }

      .dots--430 {
        display: none;
      }
      .dots--768 {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .section {
    gap: 80px;

    .header-container--2 img {
      margin-right: 104px;
    }

    .image-container {
      .dots--768 {
        display: none;
      }

      .dots--1000 {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .section {
    padding: 112px 128px;

    .header-container {
      flex-direction: row;
      justify-content: space-between;

      .header {
        min-width: 400px;
        max-width: 470px;
      }

      &--2 {
        flex-direction: row-reverse;
        img {
          margin-right: 168px;
        }

        .header {
          max-width: 860px;
        }
      }

      .text {
        width: 467px;
      }
    }

    .image-container {
      .dots--1000 {
        display: none;
      }

      .dots--1440 {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1910px) {
  .section {
    padding: 112px 240px;

    .header-container--2 {
      img {
        margin-right: 192px;
      }
      .header {
        max-width: 950px;
      }
    }

    .section {
      .header-container {
        .header {
          max-width: 620px;
        }
      }
    }

    .image-container {
      .dots--1440 {
        display: none;
      }

      .dots--1920 {
        display: block;
      }
    }
  }
}
