@import "../../styles/variables";

.heading {
  color: #fff;
  font-family: "Unbounded", sans-serif;
  font-size: 90px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  margin-top: 300px;
  line-height: 100px;
  overflow: hidden;

  &-mobile {
    display: none;
  }

  &__row {
    &--1 {
      display: flex;
      justify-content: space-between;
      z-index: 10;
      margin: 0 22%;
    }
    &--2 {
      align-self: center;
      z-index: 10;
      margin: 0 22%;
    }
    &--3 {
      align-self: flex-end;
      display: flex;
      gap: 35px;
      z-index: 10;
      margin: 0 15%;
    }
  }

  &__icon-container {
    width: 120px;
    position: relative;

    .circle-icon {
      animation-name: spin;
      animation-duration: 10000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .checkmarks-icon,
    .circle-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .checkmarks-icon {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  .text-blue {
    background: -webkit-linear-gradient($color-blue-500, $color-blue-400);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media screen and (max-width: 1580px) {
  .heading {
    &__row {
      &--1 {
        margin: 0 18%;
      }
      &--2 {
        margin: 0 15%;
      }
      &--3 {
        margin: 0 10%;
      }
    }
  }
}

@media screen and (max-width: 1380px) {
  .heading {
    &__row {
      &--1 {
        margin: 0 15%;
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  .heading {
    &__row {
      &--1 {
        margin: 0 12%;
      }
      &--3 {
        margin: 0 5%;
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  .heading {
    * {
      font-size: 68px;
    }

    &__row {
      &--1 {
        margin: 0 18%;
      }
      &--3 {
        margin: 0 15%;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .heading {
    font-size: 68px;
    &__row {
      &--1 {
        margin: 0 13%;
      }
      &--3 {
        margin: 0 10%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .heading {
    font-size: 68px;
    &__row {
      &--1 {
        margin: 0 10%;
      }
      &--3 {
        margin: 0 7%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .heading {
    * {
      font-size: 64px;
      line-height: 80px;
    }
    &__row {
      &--1 {
        padding-top: 20px;
        margin-left: 20px;
      }
      &--3 {
        margin: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .heading {
    font-size: 60px;
    &__row {
      &--1 {
        margin-left: 20px;
      }
      &--3 {
        margin: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .heading {
    font-size: 60px;
    padding: 0 40px;
    &__row {
      &--1 {
        margin-left: 20px;
        span {
          text-align: left;
        }
      }
      &--2 {
        text-align: center;
      }
      &--3 {
        text-align: right;
        flex-direction: column;
        gap: 0;
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .heading {
    font-size: 60px;
    &__row {
      &--1 {
        margin: 0 20px;
      }
      &--2 {
        margin: 0 20px;
      }
      &--3 {
        margin: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .heading {
    margin-left: 20px;
    margin-right: 20px;
    padding: 0 0 10px;
    position: relative;

    &-desktop {
      display: none;
    }

    &-mobile {
      display: grid;
      grid-template-columns: 1fr;
      position: absolute;
      z-index: 100;
      width: 90vw;
      gap: 5px;
      padding-top: 50px;
      bottom: 70px;

      span {
        &:nth-child(1) {
          grid-row: 1;
          justify-self: start;
        }
        &:nth-child(2) {
          justify-self: start;
        }
        &:nth-child(3) {
          grid-row: 3;
          justify-self: end;
        }
        &:nth-child(4) {
          grid-row: 4;
        }
        &:nth-child(5) {
          grid-row: 5;
          justify-self: end;
          background-image: linear-gradient(to right top, #a0d1f4, #4383ff);
          color: transparent;
          background-clip: text;
        }
      }
    }

    &__row {
      &--1 {
        margin: 0 10px;
      }
      &--2 {
        margin: 0;
      }
      &--3 {
        margin: 0;
      }
    }

    &__icon-container {
      width: 120px !important;
      height: 120px !important;
      position: absolute;
      right: 0 !important;
    }
  }
}

@media screen and (max-width: 440px) {
  .heading {
    & * {
      font-size: 52px !important;
      line-height: 60px;
    }
  }
}

@media screen and (max-width: 400px) {
  .heading {
    & * {
      font-size: 48px !important;
      line-height: 60px;
    }
  }
}
