.image-section {
  display: inline;
  max-height: 420px;
  position: relative;
  z-index: -2;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1e222b;
    opacity: 0.8;
    z-index: 1;
  }

  .image-container {
    background-image: url("../../media/case-study/cybertracker-bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    padding: 120px 240px;
    justify-content: space-between;
    align-items: center;
    min-height: 320px;

    .logo {
      z-index: 1;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 24px;
      z-index: 1;
      max-width: 650px;

      .title {
        margin: 0;
        font-family: "Nunito", sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 29.76px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #ffffff;
      }

      .description {
        font-family: "Nunito", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.82px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #ffffff;
      }
    }
  }
}

@media screen and (min-width: 300px) {
  .image-section {
    .image-container {
      padding: 80px 20px;
      flex-direction: column;
      gap: 50px;

      .text > .title {
        text-align: center;
        font-size: 24px;
        line-height: 34.45px;
      }

      .text > .description {
        text-align: center;
        font-size: 16px;
        line-height: 21.45px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .image-section {
    .image-container {
      padding: 80px 50px;
      flex-direction: row;

      .logo {
        padding: 0 35px;
      }

      .text > .title,
      .text > .description {
        text-align: start;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .image-section {
    .image-container {
      padding: 0 50px;
      min-height: 0;

      .text {
        padding: 150px 0;
        font-size: 52px;
        line-height: 49.77px;
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .image-section {
    .image-container {
      padding: 0 128px;
      min-height: 0;
    }
  }
}

@media screen and (min-width: 1910px) {
  .image-section {
    .image-container {
      gap: 72px;
    }
  }
}
