.react-calendar {
  max-width: 80%;
  margin-left: 10%;
  background: transparent;
  font-family: "Nunito", sans-serif;
  line-height: 1.125em;

  * {
    text-decoration: none;
  }
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #fff;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #1a1a22;
  color: #fff !important;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
  border: 2px #72aafa solid;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 14px;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #fff;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #1a1a22;
  color: #fff !important;
}

.react-calendar__tile--now {
  background: #2b2d7f;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #1a1a22;
  color: #616874 !important;
}

.react-calendar__navigation__label__labelText {
  color: #fff !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #2b2d7f;
}

.react-calendar__tile--hasActive {
  background: #2b2d7f;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile {
  color: #fff;
  padding: 6px;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #1a1a22;
  color: #fff !important;
}

.react-calendar__navigation__label {
  background-color: transparent !important;
}

.react-calendar__navigation__arrow {
  font-size: 24px !important;
}

@media screen and (min-width: 600px) {
  .react-calendar {
    max-width: 90%;
    margin-left: 5%;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 20px;
  }

  .react-calendar__tile {
    padding: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .react-calendar {
    max-width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 1300px) {
  .react-calendar {
    max-width: 100%;
    margin-left: 0;
  }
}
