.navigation {
  position: absolute;
  width: 100%;
  display: flex;
  //padding: 10px;
  justify-content: space-between;
  color: #fff;
  z-index: 100;

  & > * {
    padding: 10px;
  }

  &-hidden {
    position: sticky;
    height: 0;
    padding: 0;
    margin: 0;
  }

  &-sticky {
    background-color: #1b2027;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
  }

  &__logo {
    display: flex;
    height: 31px;
    z-index: 40;
    padding: 12px 40px;
  }

  &__actions {
    z-index: 10;
    //margin-right: 20px;
    font-family: "Nunito_Sans", sans-serif;
    font-size: 20px;
    display: flex;
    gap: 25px;
    padding-right: 40px;

    & > * {
      padding: 12px 0;
    }

    a:link,
    a:visited {
      text-decoration: none;
      color: #fff;
      margin-right: 20px;
    }

    a:hover,
    a:active {
      cursor: pointer;
      z-index: 20;
    }

    .language__name {
      width: 34px;
    }

    .language {
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      position: relative;
      min-width: 5.4rem;
      padding: 0;
      margin-right: 2rem;
      cursor: pointer;

      img {
        width: 21px;
      }

      &:hover {
        //background-color: red;
      }

      &__button {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        min-width: 5.4rem;
        padding: 12px 24px;
      }
      &__triangle {
        font-size: 0.6rem;
        margin-left: 0.5rem;
      }
      .select-buttons-container {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 12px;
        background-color: #1a1a22;
        transition:
          opacity 0.3s,
          transform 0.1s;

        &.shown {
          z-index: 2;
          opacity: 1;
          transition:
            opacity 0.3s,
            transform 0.1s;

          .language__triangle {
            transform: rotate(180deg);
          }
        }

        &.hidden {
          z-index: -10;
          opacity: 0;
          display: none;
          cursor: default;
          transition:
            opacity 0.3s,
            transform 0.1s;
          pointer-events: none;
        }

        .line {
          width: 90%;
          height: 1px;
          background-color: #000;
        }
      }

      .select-button {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 12px 24px;
        width: 5.4rem;
        cursor: pointer;
      }
    }
  }

  .menu-btn {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    display: none;
    z-index: 40;

    &__burger {
      width: 30px;
      height: 4px;
      border-radius: 5px;
      transition: all 0.5s ease-in-out;
      background-color: #fff;

      &::before,
      &::after {
        left: 50%;
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
        background-color: #fff;
      }

      &::before {
        transform: translate(-50%, -10px);
      }
      &::after {
        transform: translate(-50%, 10px);
      }
    }

    &.open .menu-btn__burger {
      transform: translateX(-50px);
      background-color: transparent;
    }

    &.open .menu-btn__burger::before {
      transform: rotate(45deg) translate(25px, -25px);
    }
    &.open .menu-btn__burger::after {
      transform: rotate(-45deg) translate(25px, 25px);
    }
  }

  .menu {
    z-index: 30;
    position: absolute;
    transition: all 0.3s;

    &--hidden {
      opacity: 0;
      pointer-events: none;
      max-height: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .navigation {
    &__actions {
      display: none;
    }
    .menu-btn {
      display: flex;
    }

    &__logo {
      padding: 12px 10px;
    }

    .menu {
      left: 50%;
      transform: translateX(-50%);
      background-color: #16161d;
      width: 100%;
      padding: 0;
      margin-top: -10px;

      .navigation__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 90px 0;
        position: relative;

        .language {
          margin: 0;
        }
      }
    }
  }
}
