.form-card {
  display: flex;
  justify-content: space-between;
  background: radial-gradient(circle at -5% -40%, #21468d 25%, #1a1a22 55%);
  padding: 50px;
  border-radius: 12px;
  margin-bottom: 50px;

  .card-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    justify-content: space-between;
    gap: 100px;
    width: 900px;

    .card-text {
      display: flex;
      flex-direction: column;
      gap: 100px;
    }

    &__heading {
      color: #fff;
      font-family: "Unbounded", sans-serif;
      font-weight: 300;
      font-size: 40px;
      margin: 0;

      img {
        margin-left: 20px;
      }
    }

    &__body {
      font-size: 20px;
      color: #fff;
      font-family: "Nunito_sans", sans-serif;
      font-weight: 300;
      width: 90%;
      margin-top: -60px;
    }

    &__email {
      font-family: "Unbounded", sans-serif;
      font-weight: 300;
      font-size: 17px;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 1px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    &__inputs-container,
    &__input-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &__input {
      position: relative;
      background-color: transparent;
      border: none;
      padding-bottom: 15px;
      border-bottom: 1px solid #eeedf2;
      font-family: "Unbounded", sans-serif;
      font-size: 18px;
      color: #fff;
      transition: all 0.3s;

      &::placeholder {
        font-family: "Unbounded", sans-serif;
        font-weight: 300;
        color: #fff;
        letter-spacing: 1px;
        overflow-wrap: break-word;
        flex-wrap: wrap;
      }

      &:focus {
        outline: none;
        border-color: #415dbd;
      }

      &:last-child::placeholder {
        white-space: normal;
      }
    }

    &__labels-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;

      p {
        color: #eeedf2;
        font-size: 16px;
        font-family: "Unbounded", sans-serif;
        letter-spacing: 1px;
        margin-top: 40px;
        margin-bottom: 5px;
        font-weight: 300;
      }
      &__rows-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__row {
        display: flex;
        gap: 16px;
      }

      .label {
        border-radius: 8px;
        padding: 8px 14px;
        background-color: #29282d;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        font-family: "Nunito_sans", sans-serif;
        font-weight: 300;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 14px 0 #3988ff;
        }
      }

      .selected {
        background: linear-gradient(to right top, #a0d1f4, #1849ec);
        color: #000;
        font-weight: 400;
      }
    }

    &__button {
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 4px;
      background: linear-gradient(203deg, #4383ff -4.85%, #a0d1f4 152.7%);
      padding: 18px 36px;
      color: #fff;
      text-align: center;
      font-family: "Nunito_Sans", sans-serif;
      font-size: 16px;
      line-height: 25px;
      text-transform: uppercase;
      border: none;
      align-self: start;
      z-index: 101;

      &:hover {
        box-shadow: 0 0 14px 0 #3988ff;
      }

      &--sent {
        background-color: #0065fc;

        & img {
          animation: bounce 2s ease-in-out infinite;
        }
      }
    }
  }
}

@media screen and (max-width: 1580px) {
  .form-card {
    gap: 5%;
    .card-text-container {
      width: 700px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .form-card {
    gap: 80px;
    .card-text-container {
      width: 650px;
    }
  }
}

@media screen and (max-width: 1386px) {
  .form-card {
    .arrow-icon {
      transform: rotate(90deg);
    }
  }
}

@media screen and (max-width: 1300px) {
  .form-card {
    .card-text-container {
      width: 468px;
      &__heading {
        font-size: 32px;

        img {
          height: 30px;
          margin-bottom: -5px;
        }
      }
    }

    .form {
      width: 48%;
      &__labels-container {
        display: flex;
        width: 100%;
        &__row {
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .form-card {
    gap: 20px !important;
    .card-text-container {
      width: 40%;
    }
    .form {
      width: 60%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .form-card {
    flex-direction: column;
    .card-text-container {
      width: 100%;
      flex-direction: row;
      gap: 20px;

      &__heading {
        font-size: 40px;
        width: 55%;
      }

      &__body {
        width: 100%;
        margin: 0;
      }

      .card-text {
        gap: 20px;
        width: 45%;
      }
    }

    .form {
      width: 100%;

      &__labels-container__rows-container {
        flex-direction: row;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .form-card {
    background: #1e222b;

    .card-text-container {
      width: 100%;
      flex-direction: column;
      gap: 50px;

      .card-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &__heading {
        width: 100%;
      }

      .card-text {
        width: 100%;
      }
    }

    .form {
      &__labels-container__rows-container {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .form-card {
    padding: 30px;

    .card-text-container {
      .card-header {
        font-size: 32px;
      }

      &__body {
        width: 90%;
      }

      &__email {
        margin-bottom: 60px;
        background-image: linear-gradient(to right top, #a0d1f4, #4383ff);
        color: transparent;
        background-clip: text;
      }
    }

    .form {
      width: 100%;
      &__input {
        &:last-child {
          padding-bottom: 35px;
        }
      }

      &__labels-container {
        display: flex;
        width: 100%;
        &__row {
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .form-card {
    .form {
      &__labels-container {
        display: flex;
        width: 90%;
        &__row {
          flex-wrap: wrap;
        }
      }
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: rotate(90deg) translateX(0);
  }
  50% {
    transform: rotate(90deg) translateX(-5px); /* Adjust the bounce height as needed */
  }
}
